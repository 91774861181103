import Lottie, { LottieComponentProps } from 'lottie-react'

import animationData from './animation1.json'

const defaultOptions: LottieComponentProps = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	height: 300,

	style: { height: '100%' }
}
export const Animation1 = (props: Omit<LottieComponentProps, 'animationData'>) => (
	<Lottie {...defaultOptions} {...props} />
)

export default Animation1
