/* eslint-disable unicorn/no-null */
/* eslint-disable unicorn/prevent-abbreviations */
import { useAnimations, useGLTF, useTexture } from '@react-three/drei'
import { GroupProps } from '@react-three/fiber'
import { MutableRefObject, useEffect, useRef } from 'react'
import { AnimationClip, Bone, Group, MeshStandardMaterial, SkinnedMesh } from 'three'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
	nodes: {
		YB_Body: SkinnedMesh
		YB_Joints: SkinnedMesh
		mixamorigHips: Bone
	}
	materials: {
		YB_Body: MeshStandardMaterial
		YB_Joints: MeshStandardMaterial
	}
}

type AnimationControllerProps = {
	ybotRef: MutableRefObject<Group | undefined | null>
	animations: AnimationClip[]
}

function AnimationController(props: AnimationControllerProps) {
	const { actions } = useAnimations(props.animations, props.ybotRef)

	// const actionOptions = Object.keys(actions)
	// Dance, Idle, Strut
	const selectedAction = 'Dance'
	// 0-2 step 0.1
	const blendDuration = 1

	useEffect(() => {
		// eslint-disable-next-line security/detect-object-injection
		actions[selectedAction]?.reset().fadeIn(blendDuration).play()
		// eslint-disable-next-line security/detect-object-injection
		return () => void actions[selectedAction]?.fadeOut(blendDuration)
	}, [actions, selectedAction, blendDuration])

	return null
}

export function YBotModel(props: GroupProps) {
	const ybotRef = useRef<Group>(null)
	const { nodes, animations } = useGLTF('ybot.glb') as GLTFResult
	const matcapBody = useTexture('/293534_B2BFC5_738289_8A9AA7.png')
	const matcapJoints = useTexture('/3A2412_A78B5F_705434_836C47.png')

	return (
		<>
			<group ref={ybotRef} {...props} dispose={null}>
				<group rotation={[Math.PI / 2, 0, 0]} scale={0.008}>
					<primitive object={nodes.mixamorigHips} />
					<skinnedMesh geometry={nodes.YB_Body.geometry} skeleton={nodes.YB_Body.skeleton}>
						<meshMatcapMaterial matcap={matcapBody} />
					</skinnedMesh>
					<skinnedMesh geometry={nodes.YB_Joints.geometry} skeleton={nodes.YB_Joints.skeleton}>
						<meshMatcapMaterial matcap={matcapJoints} />
					</skinnedMesh>
				</group>
			</group>

			<AnimationController ybotRef={ybotRef} animations={animations} />
		</>
	)
}

useGLTF.preload('ybot.glb')
