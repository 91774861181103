/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable unicorn/no-null */
import { MeshReflectorMaterial, Text, useTexture } from '@react-three/drei'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { useMemo, useRef, useState } from 'react'
import * as THREE from 'three'
import { Group, Vector2 } from 'three'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'

import { YBotModel } from './animatedModel'

// const Carla = props => {
//   const { scene } = useGLTF('/carla-draco.glb')
//   return <primitive object={scene} {...props} />
// }

const Triangle = ({ color, ...props }) => {
	const ref = useRef<Group>()
	const [r] = useState(() => Math.random() * 10_000)
	useFrame(_ => (ref.current.position.y = 0 + Math.sin(_.clock.elapsedTime + r) / 10))
	const { paths: [path] } = useLoader(SVGLoader, '/triangle.svg') // prettier-ignore
	const geom = useMemo(
		() => SVGLoader.pointsToStroke(path.subPaths[0].getPoints(), path.userData.style),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)
	return (
		<group ref={ref}>
			<mesh geometry={geom} {...props} position={[-2, 0, -3]} scale={0.007}>
				<meshBasicMaterial color={color} toneMapped={false} />
			</mesh>
		</group>
	)
}

const VideoText = ({ ready, ...props }) => {
	const [video] = useState<HTMLVideoElement>(() => {
		return document.querySelector('#videotesto')
		// return Object.assign(document.createElement('video'), {
		//   src: '/ANIMAZIONEGRAFICA.mp4',
		//   crossOrigin: 'Anonymous',
		//   loop: true,
		//   // playsInline: true,
		//   // autoPlay: true,
		//   // muted: true,
		//   id: 'videotesto'
		// })
	})

	// useEffect(() => void (ready && video && video.play()), [video, ready])

	return (
		<Text
			font="/Poppins.otf"
			fontSize={0.9}
			color="#fff"
			letterSpacing={-0.04}
			lineHeight={0.85}
			maxWidth={200}
			{...props}
			position={[0, 1.4, -2]}
		>
			{`prime
    video`}
			<meshBasicMaterial toneMapped={false}>
				<videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
			</meshBasicMaterial>
		</Text>
	)
}

const Ground = () => {
	const normal = useTexture('/SurfaceImperfections003_1K_Normal.jpg')
	const floor = useTexture('/SurfaceImperfections003_1K_var1.jpg')

	return (
		<mesh rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
			<planeGeometry args={[20, 20]} />
			<MeshReflectorMaterial
				// args={[10, 10]}
				blur={[400, 100]}
				color="#a0a0a0"
				// debug={2}
				// depthScale={0}
				// depthToBlurRatioBias={0.2}
				metalness={0.4}
				mirror={0.5}
				mixBlur={6}
				mixStrength={1.5}
				normalMap={normal}
				normalScale={new Vector2(2, 2)}
				// maxDepthThreshold={1.2}
				// minDepthThreshold={0.8}
				resolution={512}
				// rotation={[-Math.PI / 2, 0, Math.PI / 2]}
				roughnessMap={floor}
				roughness={1}
			/>
		</mesh>
	)
}

export default function TextModel({ ready }) {
	return (
		<>
			<Canvas gl={{ alpha: false }} camera={{ position: [0, 3, 100], fov: 15 }} shadows>
				<color attach="background" args={['black']} />
				<ambientLight intensity={0.8} />
				<pointLight intensity={1} position={[0, 6, 0]} />

				<Triangle color={'#fff'} />
				<YBotModel position={[-0.6, -1, 0]} />

				{/* <Robot
          scale={0.007}
          rotation={[0, Math.PI - 0.4, 0]}
          position={[-1.2, -1, 0.6]}
        /> */}

				<fog attach="fog" args={['black', 15, 20]} />

				{/* <ambientLight
          intensity={1}
          position={[10, 6, 10]}
          penumbra={1}
          angle={0.3}
        /> */}
				{/* <spotLight
          intensity={1}
          position={[10, 6, 10]}
          penumbra={1}
          angle={0.3}
        /> */}
				{/* <pointLight
          intensity={0.1}
          position={[10, 6, 10]}
          penumbra={1}
          angle={0.3}
        /> */}
				{/* <directionalLight
          intensity={1}
          position={[10, 6, 10]}
          penumbra={1}
          angle={0.3}
        /> */}

				<group position={[0, -1, 0]}>
					{/* <ambientLight intensity={0.25} /> */}
					{/* <spotLight
            intensity={1}
            angle={0.2}
            penumbra={1}
            position={[30, 30, 30]}
            castShadow
            shadow-mapSize={[512, 512]}
          /> */}
					{/* <directionalLight
            intensity={500}
            position={[-10, 10, -10]}
            color="#0f3330"
          /> */}
					{/* <Carla
            rotation={[0, Math.PI - 0.4, 0]}
            position={[-1.2, 0, 0.6]}
            scale={[0.26, 0.26, 0.26]}
          /> */}
					<VideoText ready={ready} position={[0, 1.3, -2]} />
					<Ground />
				</group>

				{/* gruppo luci */}
				<ambientLight intensity={0.5} />
				<spotLight position={[0, 10, 0]} intensity={0.3} />
				<directionalLight position={[-20, 0, -10]} intensity={0.7} />

				<Intro ready={ready} />
				{/* <OrbitControls /> */}
			</Canvas>

			{/* <Overlay {...store} /> */}
		</>
	)
}

const Intro = ({ ready }) => {
	const [vec] = useState(() => new THREE.Vector3())

	return useFrame(state => {
		if (ready) {
			state.camera.position.lerp(vec.set(state.mouse.x * 5, 3 + state.mouse.y * 2, 14), 0.05)
			state.camera.lookAt(0, 0, 0)
		}
	})
}
