/* eslint-disable unicorn/prevent-abbreviations */
import gsap from 'gsap'
import Link from 'next/link'
import ExportedImage from 'next-image-export-optimizer'
import React, { Fragment, useEffect } from 'react'

import Animation1 from '../components/animations/animation1'
import ContactForm from '../components/contactForm'
import Footer from '../components/footer'
import IntroText from '../components/introText'
import Navbar from '../components/navbar'

export default function Intro() {
	useEffect(() => {
		// https://codepen.io/esadrian/pen/KKMmVOW?editors=0010
		gsap.set('.ball', { xPercent: -50, yPercent: -50 })

		const ball = document.querySelector('.ball')
		const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
		const mouse = { x: pos.x, y: pos.y }
		const speed = 0.1

		const fpms = 60 / 1000

		const xSet = gsap.quickSetter(ball, 'x', 'px')
		const ySet = gsap.quickSetter(ball, 'y', 'px')

		const showAnimation = gsap.to(ball, {
			opacity: 1,
			scale: 1,
			paused: true,
			duration: 0.2,
			ease: 'power3'
		})

		// const timer = gsap
		//   .delayedCall(5, () => {
		//     showAnimation.reverse()
		//   })
		//   .pause()

		// special thanks to Blake Bowen for most of the code.

		window.addEventListener('mousemove', init)

		function init() {
			window.removeEventListener('mousemove', init)

			window.addEventListener('mousemove', e => {
				mouse.x = e.x
				mouse.y = e.y
			})

			document.addEventListener('mouseleave', () => {
				showAnimation.reverse()
			})

			document.addEventListener('mouseenter', e => {
				showAnimation.play()
				mouse.x = pos.x = e.x
				mouse.y = pos.y = e.y
			})

			gsap.ticker.add((_time, deltaTime) => {
				const delta = deltaTime * fpms
				const dt = 1 - Math.pow(1 - speed, delta)

				pos.x += (mouse.x - pos.x) * dt
				pos.y += (mouse.y - pos.y) * dt
				xSet(pos.x)
				ySet(pos.y)
			})

			showAnimation.play()
		}
	}, [])

	return (
		<>
			{/* <IntroTv /> */}
			<IntroText />
			{/* <div className="mx-auto h-full text-6xl">ciao</div> */}
			{/*
			<div className="ball">
				<SvgCross />
			</div> */}
			<article className="relative h-auto pb-5">
				<Navbar />

				{/* <div className="curtain after:block after:min-h-[98vh] after:content-['']">
        <div className="sticky top-5 flex min-h-[98vh] items-center justify-center mix-blend-difference invert">
          <h2 className="text-3xl text-yellow-900">Curtain Effect</h2>
        </div>
      </div> */}

				<section className="relative z-40">
					<h2 className="sticky top-16 z-10 mb-5 h-12 bg-black pl-4 pt-1 text-2xl text-yellow-300">
						Forti. Veloci. Affidabili.
					</h2>
					<div className="relative flex min-h-[calc(100vh-3rem)] flex-col items-center justify-center overflow-hidden bg-black px-4  ">
						<h3 className="text-5xl leading-[3.7rem] tracking-wide">
							Siamo un&lsquo; agenzia diffusa di produzione video professionali
						</h3>
						<p className="pt-5 text-xl leading-9">
							Forniamo una vasta gammadi produzioni audiovisive per privati e brand. Lorem ipsum dolor sit amet,
							consectetur adipiscing elit. Fusce sed leo nec eros blandit consectetur at a nunc. Donec vitae massa ut
							nunc bibendum imperdiet. Etiam accumsan efficitur metus a blandit. Vivamus ut felis at magna gravida
							vestibulum. Nam varius quam sit amet convallis pulvinar. Phasellus porta feugiat eros. Phasellus nec lorem
							ut justo vestibulum semper. Proin mattis sed libero vitae scelerisque.
						</p>
					</div>
				</section>

				<section className="relative z-30 mt-12">
					<h2 className="sticky top-16 z-10 -mt-20 mb-5 h-12 bg-black/40  py-3 px-2 pl-4 pt-1 text-2xl text-yellow-300 shadow-md backdrop-blur-xl backdrop-brightness-90 backdrop-opacity-90">
						Forti
					</h2>
					<div className="relative flex min-h-[calc(100vh-3.5rem)] flex-col items-center justify-center bg-black px-4 text-3xl">
						<Animation1 />
						<h3 className="pb-16 text-right text-3xl leading-10 tracking-wide">
							Siamo un&lsquo; agenzia diffusa di produzione video professionali
						</h3>
						<div className=" w-4/5 max-w-lg justify-self-center">
							{/* <div className="absolute inset-x-0 z-30 mx-auto mt-10 h-5 w-5 rounded-full border-2 border-red-700 bg-red-400 shadow-2xl ring-4 ring-inset ring-red-600 " /> */}
							<div className="aspect-w-9 aspect-h-16 my-5 shadow-lg  ">
								<ExportedImage
									alt="computer grafica 2AeS Group"
									className=" object-cover"
									src="/fotodiprova.jpg"
									layout="fill"
									sizes="50vw"
								/>
							</div>
						</div>
						<h3 className="pb-16 text-center text-5xl leading-10 tracking-wider">COSA</h3>
						<div className="relative grid h-auto w-full grid-flow-row grid-cols-2 gap-y-8 overflow-hidden px-5 ">
							<h4 className="justify-self-left font-Poppins my-auto text-2xl font-bold text-yellow-400">
								<span className="text-4xl">01</span> Videomaking
							</h4>
							<div className=" w-3/5 max-w-lg justify-self-end">
								{/* <div className="absolute inset-x-0 z-30 mx-auto mt-10 h-5 w-5 rounded-full border-2 border-red-700 bg-red-400 shadow-2xl ring-4 ring-inset ring-red-600 " /> */}
								<div className="aspect-w-1 aspect-h-1 shadow-lg  ">
									<ExportedImage
										alt="computer grafica 2AeS Group"
										className=" object-cover"
										src="/video-camera.png"
										layout="fill"
										sizes="50vw"
									/>
								</div>
							</div>
							<h4 className="justify-self-left my-auto text-xl text-yellow-400">
								<span className="text-4xl">02</span> <br />
								Social Strategy
							</h4>
							<div className=" w-3/5 max-w-lg justify-self-end">
								{/* <div className="absolute inset-x-0 z-30 mx-auto mt-10 h-5 w-5 rounded-full border-2 border-red-700 bg-red-400 shadow-2xl ring-4 ring-inset ring-red-600 " /> */}
								<div className="aspect-w-1 aspect-h-1 shadow-lg  ">
									<ExportedImage
										alt="computer grafica 2AeS Group"
										className=" object-cover"
										src="/social.png"
										layout="fill"
										sizes="50vw"
									/>
								</div>
							</div>

							<h4 className="justify-self-left my-auto text-xl text-yellow-400">
								<span className="text-4xl">03</span> <br /> Graphic Design
							</h4>
							<div className="w-3/5 max-w-lg justify-self-end">
								{/* <div className="absolute inset-x-0 z-30 mx-auto mt-10 h-5 w-5 rounded-full border-2 border-red-700 bg-red-400 shadow-2xl ring-4 ring-inset ring-red-600 " /> */}
								<div className="aspect-w-1 aspect-h-1 shadow-lg  ">
									<ExportedImage
										alt="computer grafica 2AeS Group"
										className=" object-cover"
										src="/pennello.png"
										layout="fill"
										sizes="50vw"
									/>
								</div>
							</div>
							<h4 className="justify-self-left my-auto text-xl text-yellow-400">
								<span className="text-4xl">04</span> <br /> Photography
							</h4>
							<div className="w-3/5 max-w-lg justify-self-end">
								{/* <div className="absolute inset-x-0 z-30 mx-auto mt-10 h-5 w-5 rounded-full border-2 border-red-700 bg-red-400 shadow-2xl ring-4 ring-inset ring-red-600 " /> */}
								<div className="aspect-w-1 aspect-h-1 shadow-lg  ">
									<ExportedImage
										alt="computer grafica 2AeS Group"
										className=" object-cover"
										src="/foto.png"
										layout="fill"
										sizes="50vw"
									/>
								</div>
							</div>

							<h4 className="justify-self-left my-auto text-xl text-yellow-400">
								<span className="text-4xl">05</span> <br /> Marketing SEO
							</h4>
							<div className="w-3/5 max-w-lg justify-self-end">
								{/* <div className="absolute inset-x-0 z-30 mx-auto mt-10 h-5 w-5 rounded-full border-2 border-red-700 bg-red-400 shadow-2xl ring-4 ring-inset ring-red-600 " /> */}
								<div className="aspect-w-1 aspect-h-1 shadow-lg  ">
									<ExportedImage
										alt="computer grafica 2AeS Group"
										className=" object-cover"
										src="/seo.png"
										layout="fill"
										sizes="50vw"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="relative z-20 mt-12">
					<h2 className="sticky top-16 z-10 -mt-20 mb-5 h-12 bg-black pl-4 pt-1 text-2xl text-yellow-300">Veloci</h2>
					<div className="relative flex min-h-[calc(100vh-4rem)] flex-col items-center justify-center bg-black px-4 text-3xl">
						<p>
							Siamo un&lsquo; agenzia diffusa di produzionevideo professionali, che fornisce una vasta gammadi
							produzioni audiovisive per privati e brand.
						</p>
						{/* <div className="absolute top-20 left-0 h-5 w-5 border-2 border-green-100 ">
              <ExportedImage
                alt="La tua immagine online 2AeS Group Srl"
                src="https://res.cloudinary.com/group-2aes/image/upload/v1637271712/coppo/pasticceria_copertina_eyf7p4.jpg"
                className="object-cover "
                layout="fill"
              />
            </div> */}
					</div>
				</section>
				<section className="relative z-10 mt-12">
					<h2 className="sticky top-16 z-10 -mt-20 mb-5 h-12 bg-black pl-4 pt-1 text-2xl text-yellow-300">
						Affidabili
					</h2>
					<div className="relative flex min-h-[calc(100vh-3.0rem)] flex-col items-stretch justify-start bg-black pt-8 ">
						<h3 className="px-4 text-center text-3xl"> Siamo videomaker, eccocosa possiamo realizzare</h3>

						<div className="mt-5 max-w-lg ">
							<div className="aspect-w-4 aspect-h-5 pt-6 ">
								<video autoPlay muted playsInline loop>
									<source src="/ANIMAZIONEGRAFICA.mp4" />
								</video>
							</div>
						</div>

						<h3 className="px-4 pb-3  text-center text-3xl"> tutti i servizi che possiamo realizzare</h3>
						<Link href={'/testscroll'}>
							<div className="font-Poppins mx-10 mb-5 justify-start rounded-md bg-white p-4 text-center text-2xl text-black underline shadow-md shadow-slate-500">
								portfolio prime agency
							</div>
						</Link>
						<div className="grid-flow-rows grid gap-y-4 px-5">
							<div className="justify-self-start rounded bg-yellow-400 shadow-md shadow-gray-500">
								<h4 className="font-Poppins p-4 text-xl font-bold text-white">VIDEO DI EVENTI</h4>
							</div>
							<div className="justify-self-center rounded bg-yellow-400 shadow-md shadow-gray-500">
								<h4 className="font-Poppins p-4 text-xl font-bold text-white">VIDEO ISTITUZIONALI</h4>
							</div>
							<div className="justify-self-start rounded bg-yellow-400 shadow-md shadow-gray-500">
								<h4 className="font-Poppins p-4 text-xl font-bold text-white">VIDEO AZIENDALI</h4>
							</div>
							<div className="justify-self-center rounded bg-yellow-400 shadow-md shadow-gray-500">
								<h4 className="font-Poppins p-4 text-xl font-bold text-white">RIPRESE AEREE CON DRONE</h4>
							</div>
							<div className="justify-self-end rounded bg-yellow-400 shadow-md shadow-gray-500">
								<h4 className="font-Poppins p-4 text-xl font-bold text-white">STREAMING EVENTI</h4>
							</div>
							<div className="justify-self-end rounded bg-yellow-400 shadow-md shadow-gray-500">
								<h4 className="font-Poppins p-4 text-xl font-bold text-white">VIDEO INDUSTRIALI</h4>
							</div>
							<div className="justify-self-center rounded bg-yellow-400 shadow-md shadow-gray-500">
								<h4 className="font-Poppins p-4 text-xl font-bold text-white">SPOT CINEMA E TV</h4>
							</div>

							{/*			<div className="justify-self-center rounded bg-yellow-400 shadow-md shadow-gray-500">
							<h4 className="font-Poppins p-4 text-xl font-bold text-white">VIDEO CORSI E LEZIONI</h4>
						</div>
						<div className="justify-self-center rounded bg-yellow-400 shadow-md shadow-gray-500">
							<h4 className="font-Poppins p-4 text-xl font-bold text-white">VIDEO MARKETING</h4>
						</div>
					 						<div className="justify-self-center rounded bg-yellow-400 shadow-md shadow-gray-500">
							<h4 className="font-Poppins p-4 text-xl font-bold text-white">DOCUMENTARI</h4>
						</div>
						<div className="justify-self-center rounded bg-yellow-400 shadow-md shadow-gray-500">
							<h4 className="font-Poppins p-4 text-xl font-bold text-white">VIDEO PROMOZIONE TURISMO</h4>
						</div>
						<div className="justify-self-center rounded bg-yellow-400 shadow-md shadow-gray-500">
							<h4 className="font-Poppins p-4 text-xl font-bold text-white">VIDEOCLIP MUSICALI</h4>
						</div>
						<div className="justify-self-center rounded bg-yellow-400 shadow-md shadow-gray-500">
							<h4 className="font-Poppins p-4 text-xl font-bold text-white">VIDEO PER IL SOCIALE</h4>
						</div>
						<div className="justify-self-center rounded bg-yellow-400 shadow-md shadow-gray-500">
							<h4 className="font-Poppins p-4 text-xl font-bold text-white">VIDEO MOTION GRAPHICS</h4>
						</div>
						<div className="justify-self-center rounded bg-yellow-400 shadow-md shadow-gray-500">
							<h4 className="font-Poppins p-4 text-xl font-bold text-white">VIDEO TUTORIAL</h4>
						</div> */}
						</div>
						<p className="px-4 pt-8  text-left text-2xl text-yellow-400"> ... e tanto altro</p>
					</div>
				</section>
			</article>
			<ContactForm />
			<Footer />
		</>
	)
}
