import Link from 'next/link'

export default function Navbar() {
	return (
		<nav className="sticky inset-x-0 top-0 z-50 flex justify-between bg-black/40 py-3 px-2 text-yellow-300 backdrop-blur-xl backdrop-brightness-90 backdrop-opacity-90  transition-all">
			<Link href={'/'}>
				<div className=" text-3xl font-bold leading-4 lg:text-4xl">
					<div className=""> prime</div>
					<div className="text-bg-yellow-300 inline-block pl-5 text-base">&#9656;</div>
					<div className="-mt-4 inline-block text-xl text-white lg:text-2xl">agency</div>
				</div>
			</Link>
			<ul className=" flex cursor-pointer flex-wrap items-center space-x-3 text-lg  md:text-2xl">
				<li className="dot-under">
					<Link href="/team-prime-agency-videomaker" passHref>
						chi
					</Link>
				</li>
				<li className="dot-under">
					<Link href="/videomaker-torino" passHref>
						cosa
					</Link>
				</li>
				<li className="dot-under">
					<Link href="" passHref>
						come
					</Link>
				</li>
				<li className="dot-under">
					<Link href="" passHref>
						dove
					</Link>
				</li>
			</ul>
		</nav>
	)
}
