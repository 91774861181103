/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable unicorn/no-null */
import clsx from 'clsx'
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from 'react'

import TextModel from './models/text'

interface IReady {
	setReady: Dispatch<SetStateAction<boolean>>
}

const Ready = ({ setReady }: IReady) => {
	useEffect(() => () => void setReady(true), [setReady])
	return null
}

export default function IntroText() {
	const [clicked, setClicked] = useState(false)
	const [ready, setReady] = useState(false)
	const [videoReady, setVideoReady] = useState(false)

	return (
		<div className="scroll-indicator h-full w-full scroll-p-14">
			<Suspense fallback={<Ready setReady={setReady} />}>
				<TextModel ready={ready && clicked && videoReady} />
			</Suspense>
			<div
				className={clsx(
					'absolute left-0 top-0 flex h-full w-full items-center justify-center overflow-hidden bg-black transition-all duration-1000',
					ready ? 'bg-black/80' : 'text-[#606060]',
					clicked && 'pointer-events-none opacity-0'
				)}
			>
				<button
					onClick={() => {
						setClicked(true)
						document.querySelector<HTMLVideoElement>('#videotesto').play()
					}}
					disabled={!ready}
				>
					{!ready ? 'loading' : 'click to continue'}
				</button>
				<video
					id="videotesto"
					loop
					// muted
					playsInline
					crossOrigin="anonymous"
					className="hidden"
					onCanPlay={() => setVideoReady(true)}
				>
					{/* <source src="textures/sintel.ogv" type='video/ogg; codecs="theora, vorbis"'> */}
					<source
						src="/ANIMAZIONEGRAFICA.mp4"
						type="video/mp4"
						// type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
					/>
				</video>
			</div>
		</div>
	)
}
