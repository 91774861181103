import Link from 'next/link'
import { toast } from 'react-toastify'

/* import * as gtag from '@/lib/Gtag' */

// const SERVERLESS_FN_URL = 'http://localhost:8787/submit'
// const SERVERLESS_FN_URL = '/api/submit'
const SERVERLESS_FN_URL = 'https://mario.it'

const handleSubmit = async event => {
	event.preventDefault()
	/*   gtag.event('contactSubmit') */

	const formData = new FormData(event.target)

	toast.promise(
		fetch(SERVERLESS_FN_URL, {
			method: 'POST',
			body: formData
		}),
		{
			pending: 'Invio in corso...',
			success: 'Invata! 👌',
			error: 'Errore!! 🤯'
		}
	)

	// let options = {
	//   // headers: {
	//   //   'Content-Type': 'multipart/form-data',
	//   //   'Access-Control-Allow-Origin': '*'
	//   // },
	//   method: 'POST',
	//   body: formData
	// }

	// fetch(SERVERLESS_FN_URL, options)
	//   .then(response => toast.success(response))
	//   .catch(error => toast.error(error))
}

export default function ContactForm() {
	return (
		<section className="relative mb-6 px-6 pt-10  text-yellow-400" id="contactform">
			<hr className="my-6 h-px border-none bg-gray-300 " />
			<h2 className="font-heading font-Poppins text-yellow-400sm:text-5xl pb-5 text-center text-4xl font-normal md:text-6xl">
				Contattaci
			</h2>
			<p className="text-center">testo</p>
			<form
				// action={SERVERLESS_FN_URL}
				// method="POST"
				onSubmit={handleSubmit}
				className="font-Poppins mx-auto mt-6 mb-12 grid max-w-xl grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
			>
				<div className=" text-yellow-400">
					<label htmlFor="first_name" className=" block text-sm font-medium text-yellow-400">
						Nome
					</label>
					<div className="mt-1">
						<input
							type="text"
							name="first_name"
							id="first_name"
							autoComplete="given-name"
							placeholder=""
							required
							className="form-focus block w-full rounded-md bg-black py-3 px-4 text-white shadow-sm"
						/>
					</div>
				</div>
				<div>
					<label htmlFor="last_name" className="block text-sm font-medium text-yellow-400">
						Cognome
					</label>
					<div className="mt-1">
						<input
							type="text"
							name="last_name"
							id="last_name"
							autoComplete="family-name"
							placeholder=""
							required
							className="form-focus block w-full rounded-md bg-black py-3 px-4 text-white shadow-sm"
						/>
					</div>
				</div>
				<div>
					<label htmlFor="email" className="block text-sm font-medium text-yellow-400">
						Email
					</label>
					<div className="mt-1">
						<input
							id="email"
							name="email"
							type="email"
							autoComplete="email"
							placeholder=""
							required
							className="form-focus block w-full rounded-md bg-black py-3 px-4 text-white  shadow-sm"
						/>
					</div>
				</div>
				<div>
					<div className="flex justify-between">
						<label htmlFor="phone" className="block text-sm font-medium text-yellow-400">
							Telefono
						</label>
						<span id="phone-optional" className="text-sm text-yellow-400">
							(opzionale)
						</span>
					</div>
					<div className="mt-1">
						<input
							type="text"
							name="phone"
							id="phone"
							autoComplete="tel"
							className="form-focus block w-full rounded-md bg-black py-3 px-4 text-white  shadow-sm"
							placeholder=""
							aria-describedby="phone-optional"
						/>
					</div>
				</div>
				<div className="sm:col-span-2">
					<label htmlFor="subject" className="block text-sm font-medium text-yellow-400">
						Oggetto
					</label>
					<div className="mt-1">
						<input
							type="text"
							name="subject"
							id="subject"
							placeholder=""
							required
							className="form-focus block w-full rounded-md bg-black py-3 px-4 text-white  shadow-sm"
						/>
					</div>
				</div>
				<div className="sm:col-span-2">
					<div className="flex justify-between">
						<label htmlFor="message" className="block text-sm font-medium text-yellow-400">
							Messaggio
						</label>
						<span id="message-max" className="text-sm">
							Massimo 500 caratteri
						</span>
					</div>
					<div className="mt-1">
						<textarea
							id="message"
							name="message"
							rows={4}
							maxLength={500}
							className="form-focus block w-full rounded-md bg-black py-3 px-4 text-white shadow-sm"
							aria-describedby="message-max"
							placeholder=""
							required
							defaultValue=""
						/>
					</div>
				</div>

				<div className="sm:col-span-2 sm:flex sm:justify-between">
					<div>
						<label className="mt-4 flex h-12 items-center space-x-3" htmlFor="checkPrivacy">
							<input
								required
								type="checkbox"
								id="checkPrivacy"
								className="border-brand-hot checked:border-brand-hot checked:bg-brand-hot h-5 w-5 cursor-pointer rounded-sm border
              bg-white bg-center
              transition
              duration-200 focus:outline-none "
							/>
							<span className="cursor-pointer">
								Ho letto e accetto la
								<Link href="/privacypolicy" passHref>
									<a className="border-whiteBrand border-b-2">privacy policy</a>
								</Link>
							</span>
						</label>
					</div>
					<button
						type="submit"
						className="umami--click--contactform-send hover:bg-brand-hot mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-[#283136]
            px-6 py-3 text-base font-medium text-white shadow-sm
            sm:w-auto"
					>
						Invia
					</button>
				</div>
			</form>
		</section>
	)
}
